import * as React from "react";
import { graphql, PageProps } from "gatsby";

// Components
import Layout from "components/Layout";
import Main from "components/Layout/Main";
import HomeArticlesList from "components/Sections/Home/HomeArticlesList";
import HomeSongsList from "components/Sections/Home/HomeSongsList";
import HomeCarousel from "components/Sections/Home/HomeCarousel";
import DefaultSidebar from "components/Sections/Sidebar/DefaultSidebar";
// Models
import { IArticle } from "models/Article";
import { ISong } from "models/Song";
import { IOptionalMetaProps } from "models/Seo";

type DataProps = {
  articles: {
    nodes: IArticle[];
  };
  featured: {
    nodes: IArticle[];
  };
  songs: {
    nodes: ISong[];
  };
  siteSearchIndex: {
    index: any;
  };
};

const HomePage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const featuredArticles = data.featured.nodes;
  const songs = data.songs.nodes;
  const articles = data.articles.nodes;

  const seo: IOptionalMetaProps = {
    title: "Inicio",
  };

  return (
    <Layout seo={seo}>
      <Main sidebar>
        <HomeCarousel slides={featuredArticles} />
        <h2 className="title">Últimas traducciones</h2>
        <HomeSongsList songs={songs} />

        <h2 className="title">Últimos artículos</h2>
        <HomeArticlesList articles={articles} />
      </Main>
      <DefaultSidebar />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    siteSearchIndex {
      index
    }

    featured: allMdx(
      filter: { frontmatter: { featured: { eq: true } } }
      limit: 10
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        fields {
          slug
          collection
        }
        frontmatter {
          title
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 920
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }

    songs: allMdx(
      filter: { fields: { collection: { eq: "songs" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 10
    ) {
      nodes {
        fields {
          slug
          author_name
        }
        frontmatter {
          title
          authors
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 50
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }

    articles: allMdx(
      filter: {
        fields: { collection: { eq: "articles" } }
        frontmatter: { tags: { ne: "WIP" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 4
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD [de] MMMM, YYYY", locale: "es")
          title
          description

          thumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 435
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`;
