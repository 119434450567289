import * as React from "react"
import { Link } from "gatsby"

// Components
import HomeSongItem from "./HomeSongItem"
// Models
import { ISong } from "models/Song"
// Styles
import * as styles from "./HomeSongsList.module.css"

type HomeSongsListProps = {
  songs: ISong[]
}

const HomeSongsList: React.FC<HomeSongsListProps> = ({ songs }) => {
  return (
    <>
      <section className={styles.home__songs}>
        {songs.map(song => (
          <HomeSongItem key={song.fields.slug} song={song} />
        ))}
      </section>
      <Link to="/songs" className={styles.home__view_more_button}>
        Ver todo
      </Link>
    </>
  )
}

export default HomeSongsList
