import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"

// Components
import { IArticle } from "models/Article"
// Styles
import * as styles from "./HomeArticleItem.module.css"

type HomeArticleItemProps = {
  article: IArticle
}

const HomeArticleItem: React.FC<HomeArticleItemProps> = ({ article }) => {
  const { title, description, thumbnail, date } = article.frontmatter
  const image = thumbnail ? getImage(thumbnail.childImageSharp.gatsbyImageData) : null
  return (
    <Link key={article.fields.slug} to={`/articles${article.fields.slug}`} className={styles.home__article_card}>
      {image ? (
        <GatsbyImage className={styles.home__article_thumbnail} image={image} alt={title} />
      ) : (
        <StaticImage className={styles.home__article_thumbnail} src={"../../../images/default.webp"} alt={title} />
      )}
      <div className={styles.home__article_content}>
        <h3 className={styles.home__article_name}>{article.frontmatter.title}</h3>
        <small className={styles.home__article_details}>Publicado el {date}</small>
        <p className={styles.home__article_excerpt}>{description}</p>
      </div>
    </Link>
  )
}

export default HomeArticleItem
