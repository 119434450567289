import * as React from "react"
import ReactCarousel, { arrowsPlugin, autoplayPlugin } from "@brainhubeu/react-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import * as styles from "./Carousel.module.css"

type CarouselProps = {}
const Carousel: React.FC<CarouselProps> = ({ children }) => {
  return (
    <>
      <ReactCarousel
        className={styles.carousel}
        plugins={[
          {
            resolve: arrowsPlugin,
            options: {
              arrowLeft: (
                <span className={styles.arrow_left}>
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} />
                </span>
              ),
              arrowLeftDisabled: (
                <span className={styles.arrow_left}>
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} />
                </span>
              ),
              arrowRight: (
                <span className={styles.arrow_right}>
                  <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                </span>
              ),
              arrowRightDisabled: (
                <span className={styles.arrow_right}>
                  <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                </span>
              ),
              addArrowClickHandler: true,
            },
          },
          "infinite",
          {
            resolve: autoplayPlugin,
            options: {
              interval: 5000,
            },
          },
        ]}
        animationSpeed={500}
        draggable
      >
        {children}
      </ReactCarousel>
    </>
  )
}

export default Carousel
