import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getNamesInLineFromKebabCase } from "functions"

// Models
import { ISong } from "models/Song"
// Styles
import * as styles from "./HomeSongItem.module.css"

type HomeSongItemProps = {
  song: ISong
}

const HomeSongItem: React.FC<HomeSongItemProps> = ({ song }) => {
  const { slug } = song.fields
  const { title, thumbnail, authors } = song.frontmatter
  const imageSong = thumbnail ? getImage(thumbnail.childImageSharp.gatsbyImageData) : undefined
  const authors_names = getNamesInLineFromKebabCase(authors)

  return (
    <Link key={slug} to={`/songs${slug}`} className={styles.home__song_card}>
      {imageSong && <GatsbyImage className={styles.imageSong} image={imageSong} alt={title} />}
      <h3 className={styles.home__song_name}>
        {title} - {authors_names}
      </h3>
    </Link>
  )
}

export default HomeSongItem
