import * as React from "react"
import { Link } from "gatsby"

// Components
import HomeArticleItem from "./HomeArticleItem"
// Models
import { IArticle } from "models/Article"
// Styles
import * as styles from "./HomeArticlesList.module.css"

type HomeArticlesListProps = {
  articles: IArticle[]
}

const HomeArticlesList: React.FC<HomeArticlesListProps> = ({ articles }) => {
  return (
    <>
      <section className={styles.home__articles}>
        {articles.map(article => (
          <HomeArticleItem key={article.fields.slug} article={article} />
        ))}
      </section>
      <Link to="/articles" className={styles.home__view_more_button}>
        Ver todo
      </Link>
    </>
  )
}

export default HomeArticlesList
