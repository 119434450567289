import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import Carousel from "components/UIElements/Carousel"
// Models
import { IArticle } from "models/Article"
import { ISong } from "models/Song"
// Styles
import * as styles from "./HomeCarousel.module.css"

type HomeCarouselProps = {
  slides: IArticle[] | ISong[]
}

const HomeCarousel: React.FC<HomeCarouselProps> = ({ slides }) => {
  if (typeof window !== "undefined") {
    return (
      <Carousel>
        {slides.map(slide => {
          const { title, thumbnail } = slide.frontmatter
          const { collection } = slide.fields
          const image = thumbnail ? getImage(thumbnail.childImageSharp.gatsbyImageData) : null
          return (
            <Link key={slide.fields.slug} className={styles.carousel_card} to={`/${collection}${slide.fields.slug}`}>
              {image && <GatsbyImage loading="eager" className={styles.carousel_image} image={image} alt={title} />}
              <div className={styles.carousel_content}>
                <h2 className={styles.carousel_title}>{title}</h2>
              </div>
            </Link>
          )
        })}
      </Carousel>
    )
  }
  return <></>
}

export default HomeCarousel
